import styled from "styled-components"
import { colors } from "./variables"

export const IndexStyle = styled.div`
  .allAreas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100vh;

    .singleArea {
      position: relative;
      background-size: cover;
      background-position: center center;
      display: grid;
      grid-template-rows: 200px 100px;
      justify-content: center;
      align-content: center;
      padding-left: 10%;
      padding-right: 10%;

      & > * {
        position: relative;
        z-index: 2;
      }
      &:before {
        /* content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1; */
      }
      &.single-area-2:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 1;
      }

      .image {
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 100%;
      }

      h1 {
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-family: "Barlow", sans-serif;
        font-size: 6rem;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.3;
        letter-spacing: 1.04px;
      }

      p {
        color: #fff;
        margin-top: 25px;
        font-size: 2.4rem;
        max-width: 90%;
        font-family: "Barlow", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.4;
        letter-spacing: 0.08px;
      }
      .startAreaLinkContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: fit-content;
      }
      .startAreaLink {
        color: #ffffff;
        padding: 10px 0 10px 0;
        font-family: "Barlow", sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.89;
        letter-spacing: 0.45px;
        text-align: left;
        text-decoration: underline;
        text-decoration-color: ${colors.orange};

        &:hover > .startAreaLinkArrow {
          transform: translate(5px);
        }
      }
      .startAreaLinkArrow {
        fill: ${colors.orange};
        stroke: ${colors.orange};
        height: 0.8em;
        width: 25px;
        margin: 0 auto;
        transform: translate(0px);
        transition: transform 0.5s ease;
        -o-transition: transform 0.5s ease;
        -moz-transition: transform 0.5s ease;
        -webkit-transition: transform 0.5s ease;
      }
    }
  }

  .btnLink {
    text-transform: uppercase;
    background-color: ${colors.orange};
    color: #ffffff;
    border: none;
    border-radius: 0;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: 0.5px;
    text-align: center;
    padding: 5px 5px;
    font-size: 1.6rem;
    @media only screen and (min-width: 2100px){
      font-size: 2.2rem;
    }
    box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
    height: 2em;
    width: 150px;
    white-space: nowrap;
    margin-top: 3rem;
    transition: background-color 0.5s ease, box-shadow 0.5s ease;
    -o-transition: background-color 0.5s ease, box-shadow 0.5s ease;
    -moz-transition: background-color 0.5s ease, box-shadow 0.5s ease;
    -webkit-transition: background-color 0.5s ease, box-shadow 0.5s ease;

    &:hover {
      background-color: ${colors.orangeDark};
      box-shadow: -3px 6px 8.5px 0 rgba(0, 0, 0, 0.13);
    }

    &:focus {
      outline: 0;
    }
  }
  .btn-visible,
  .btn-hidden {
    opacity: 0;
    visibility: hidden;
    display: none;
  }
  .icon-align {
    vertical-align: text-bottom;
  }
  .about-button {
    margin-top: 15px;
    border: none;
    text-align: center;
    padding: 0;
    color: ${colors.whiteBg};
  }

  .limitWidth {
    padding: 120px 100px 120px 6.5vw;
  }

  .noPaddingBottom {
    padding-bottom: 0px;
  }

  .bgDarker {
    background-color: ${colors.lightestGrey};
  }

  .bgLighter {
    background-color: ${colors.whiteBg};
  }

  .about {
    position: relative;

    .title > * {
      position: relative;
      z-index: 2;
    }
    .text {
      margin-top: 50px;

      p {
        color: ${colors.darkGrey};
        margin-top: 25px;
        width: 28vw;
        font-size: 2rem;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.67;
        letter-spacing: 0.2px;
      }
    }
    .img {
      position: absolute;
      right: 0;
      bottom: -1%;
      max-width: 60vw;
      width: 60vw;
    }
    .img .gatsby-image-wrapper {
      width: 100%;
      pointer-events: none;
      box-shadow: 8px 15px 10px rgba(0, 0, 0, 0.14);
    }
  }

  .clients {
    padding-top: 200px;
    padding-bottom: 200px;
    padding-right: 0px;

    .titleClients {
      margin-bottom: 2.55vw;
    }
  }

  .news {
    padding: 0px 0px 0px 6.5vw;
    margin: 200px 100px 200px 0px;

    .title {
      position: relative;
      top: -40px;
    }
  }

  @media screen and (min-width: 2560px) {
    .allAreas {
      .singleArea {
        grid-template-rows: 230px 100px;
        h1 {
          font-size: 8rem;
        }
        p{
          font-size: 3.2rem;
        }
      }
    }
    .about {
      .text {
        p {
          font-size: 2.4rem;
        }
      }
      .img{
        max-width: 1500px;
        width: 60vw;
      }
    }
  }

  @media screen and (min-width: 1921px) and (max-width: 2559px) {
    .allAreas {
      .singleArea {
        grid-template-rows: 230px 100px;
        h1 {
          font-size: calc(60px + 20 * (100vw - 1920px) / 640);
        }
        p{
          font-size: calc(24px + 8 * (100vw - 1920px) / 640);
        }
      }
    }
    .about {
      .text {
        p {
          font-size: calc(20px + 4 * (100vw - 1920px) / 640);
        }
      }
      .img{
        max-width: 50vw;
        width: 50vw;
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .about {
      padding-bottom: 0px;
    }
    .about .text {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 25px;
    }
    .about .text p {
      width: unset;
      margin: unset;
    }
    .about .img {
      position: relative;
      width: 100%;
      margin-right: 0;
      margin-left: auto;
      right: -100px;
      bottom: -25px;
    }
    .about .img .gatsby-image-wrapper {
      transform: unset;
    }
  }

  @media screen and (max-width: 1350px) {
    .allAreas {
      .singleArea {
        grid-template-rows: 260px 100px;
      }
    }
  }

  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .limitWidth {
      /* og 140px 100px 100px 6.5vw */
      padding: 120px 30px 120px 30px;
    }

    .allAreas {
      .singleArea {
        grid-template-rows: 180px 100px;
        h1 {
          font-size: 4.8rem;
        }
        p {
          font-size: 1.3rem;
        }
        .btnLink {
          width: 11vw;
        }
      }
    }

    .about {
      padding-bottom: 0px;
      .title {
        h4 {
          font-size: 1.6rem;
        }
        h1 {
          font-size: 6rem;
          line-height: 60px;
          letter-spacing: 3px;
        }
      }
      .text {
        p {
          font-size: 1.6rem;
          line-height: 20px;
          width: unset;
        }
      }
    }
    .clients,
    .clients2 {
      padding-right: 0;
      .titleClients {
        h4 {
          font-size: 1.6rem;
        }
        h1 {
          font-size: 6rem;
          line-height: 60px;
          letter-spacing: 3px;
        }
      }
    }
    .news {
      .title {
        h4 {
          font-size: 1.6rem;
        }
        h1 {
          font-size: 6rem;
          line-height: 60px;
          letter-spacing: 3px;
        }
      }
      margin-right: 30px;
    }
  }
  @media screen and (width: 768px) {
    .allAreas {
      grid-template-columns: none;
      grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
      height: unset;

      .singleArea {
        box-sizing: border-box;
        grid-template-rows: 150px 100px;
        padding-top: 100px;
        h1 {
          font-size: 3.6rem;
        }
        p {
          font-size: 1.7rem;
          font-weight: 400;
        }
        .btnLink {
          margin-top: 25px;
          font-size: 1.3rem;
          width: 85px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .limitWidth {
      /* og 140px 100px 100px 6.5vw */
      padding: 70px 32px 60px 32px;
    }

    .allAreas {
      grid-template-columns: none;
      grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
      height: unset;

      .singleArea {
        box-sizing: border-box;
        grid-template-rows: 150px 100px;
        padding-top: 100px;
        h1 {
          font-size: 2.4rem;
        }
        p {
          font-size: 1.3rem;
          font-weight: 400;
        }
        .btnLink {
          margin-top: 25px;
          font-size: 1.3rem;
          width: 85px;
        }
      }
    }
    .about {
      padding-bottom: 0px;
      .title {
        h4 {
          font-size: 1.2rem;
        }
        h1 {
          font-size: 2.5rem;
          line-height: 30px;
          letter-spacing: 2px;
        }
      }
      .text {
        margin-top: 25px;
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
        p {
          font-size: 1.2rem;
          line-height: 15px;
          width: unset;
          margin-top: 15px;
        }
      }
      .img {
        width: 100%;
        position: relative;
        right: -32px;
        img {
          max-width: 100%;
        }
      }
    }
    .know-visible {
      opacity: 1;
      max-height: 300px;
      transition: max-height 1s ease-out, opacity 0.5s ease-out,
        visibility 0.5s ease-out;
      visibility: visible;
    }
    .know-hidden {
      opacity: 0;
      max-height: 0;
      visibility: hidden;
    }
    .btn-visible {
      opacity: 1;
      visibility: visible;
      position: absolute;
      display: block;
    }
    .btn-hidden {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      display: block;
    }
    .clients,
    .clients2 {
      padding-right: 0px;
      padding-top: 90px;
      .titleClients {
        h4 {
          font-size: 1.2rem;
        }
        h1 {
          font-size: 2.5rem;
          line-height: 30px;
          letter-spacing: 2px;
        }
      }
    }

    .news {
      padding-top: 20px;
      margin-top: 65px;
      margin-bottom: 65px;
      margin-right: 30px;

      .title {
        h4 {
          font-size: 1.2rem;
        }
        h1 {
          font-size: 2.5rem;
          line-height: 30px;
          letter-spacing: 2px;
        }
      }
    }
  }
`
